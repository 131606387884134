<mat-card>

  <mat-card-title>
    Warranty Claim Form
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <hr />
      <h3 class="section-title">Warranty Claim</h3>
      <div class="form-hint-desc">
        <span class="form-hint">
          Please fill your warranty claim details below. All details are required.
        </span>
        <mat-icon>help_outline</mat-icon>
      </div>

      <section>

        <mat-form-field appearance="fill" class="col-1-1-1">
          <mat-label>Customer</mat-label>
          <input #customerAutoInput *ngIf="!isDealer" matInput placeholder="Type to Search..."
            formControlName="selectedCustomer" [matAutocomplete]="customerAuto" type="text" />
          <mat-autocomplete #customerAuto="matAutocomplete" [displayWith]="displayCustomerWith" autoActiveFirstOption>
            <mat-option disabled>
              <div class="ac-span ac-span-header ac-span-20">CUST #</div>
              <div class="ac-span ac-span-header ac-span-50">NAME</div>
              <div class="ac-span ac-span-header ac-span-30">LOCATION</div>
            </mat-option>
            <mat-option *ngFor="let c of filteredCustomers | async" [value]="c">
              <div class="ac-span ac-span-20">{{c.customer}}</div>
              <div class="ac-span ac-span-50">{{c.name}}</div>
              <div class="ac-span ac-span-30">{{c.line1}} {{c.city}}, {{c.state}}</div>
            </mat-option>
          </mat-autocomplete>
          <mat-select *ngIf="isDealer" formControlName="selectedCustomer">
            <mat-option *ngFor="let c of filteredCustomers | async" [value]="c">{{c.customer}}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.selectedCustomer.invalid">{{getSelectedCustomerError()}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-1-1-1">
          <mat-label>Ship To</mat-label>
          <mat-select formControlName="selectedShipTo" [disabled]="!f.selectedCustomer.value">
            <mat-option value="ADD_NEW">ADD NEW...</mat-option>
            <mat-option *ngFor="let st of shipTos" [value]="st.ship">{{st.name}} - {{st.line1}} ({{st.city}},
              {{st.state}})</mat-option>
          </mat-select>
          <mat-error *ngIf="f.selectedShipTo.invalid">{{getSelectedShipToError()}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-2-1-1">
          <mat-label>Dealer Claim Number</mat-label>
          <input matInput formControlName="dealerClaimNumber" type="text" />
          <mat-error *ngIf="f.dealerClaimNumber.invalid">{{getDealerClaimNumber()}}</mat-error>
          <mat-error *ngIf="f.dealerClaimNumber?.hasError('pattern')">{{"Invalid format"}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-4-2-1">
          <mat-label>Allow Partial Shipments?</mat-label>
          <input matInput formControlName="partialShipment" type="text" [hidden]="true" />
          <mat-checkbox formControlName="partialShipment">Yes</mat-checkbox>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-4-2-1">
          <mat-label>Freight</mat-label>
          <input matInput type="text" formControlName="freight" />
          <!-- <mat-error *ngIf="f.freight.invalid">{{getFreightError()}}</mat-error> -->
          <mat-error *ngIf="f.freight?.hasError('pattern')">{{"Invalid format"}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-2-1-1">
          <mat-label>Serial Number</mat-label>
          <input matInput formControlName="serialNumber" type="text" />
          <mat-error *ngIf="f.serialNumber.invalid">{{getSerialNumberError()}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-4-2-1">
          <mat-label>Retail Sale Date</mat-label>
          <input matInput type="text" id="retailSaleDate" />
          <!-- <mat-error *ngIf="f.retailSaleDate.invalid">{{getRetailSaleDateError()}}</mat-error> -->
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-4-2-1">
          <mat-label>Failure Date</mat-label>
          <input matInput type="text" id="failureDate" />
          <!-- <mat-error *ngIf="f.failureDate.invalid">{{getFailureDateError()}}</mat-error> -->

        </mat-form-field>

        <mat-form-field appearance="fill" class="col-1-1-1 failure-desc">
          <mat-label>Failure description:</mat-label>
          <textarea matInput style="resize: none; height: 120px;" formControlName="failureDescription"></textarea>
          <mat-error *ngIf="f.failureDescription.invalid" class="failure-error">{{getFailureDescriptionError()}}</mat-error>
        </mat-form-field>

      </section>



      <hr />

      <h3 class="section-title">Order Items:</h3>

      <div class="form-hint-desc parts">
        <span>Please add each part AND labor used to service this warranty claim. To enter labor type "Warranty Labor" as the part number. The hourly rate can be set within each item.
        </span>
        <mat-icon>help_outline</mat-icon>
      </div>

      <button mat-flat-button (click)="openAddItemModal()" [disabled]="!addItemEnabled()">Add Item</button>

      <span *ngIf="!warrantyClaimItemsHasError()" class="grid-header-hint">Double-click a <b>Qty Ordered</b> cell to edit</span>
      <span *ngIf="warrantyClaimItemsHasError() && submitAttempted"
        class="grid-header-hint grid-error">{{getWarrantyClaimItemsError()}}</span>

      <section>

        <ag-grid-angular (cellValueChanged)="onCellValueChanged($event)" class="ag-theme-alpine"
          [columnDefs]="columnDefs" domLayout="autoHeight" [editType]="'cell'"
          [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)" [rowData]="warrantyClaimItems"
          style="width: 100%;"></ag-grid-angular>

      </section>

      <div class="content-right">
        <button mat-flat-button (click)="submit()" [disabled]="formSubmitted">SUBMIT WARRANTY CLAIM</button>
      </div>

    </form>
  </mat-card-content>

</mat-card>
