import { _deserializable } from '../_deserializable';
import { WarrantyItem } from '../models/warranty-item.model';

export class SubmitWarrantyClaimRequest extends _deserializable {

  public channel: string;
  public comments: string;
  public customer: string;
  // public orderDate: Date;
  // public ownerContact: string;
  // public ownerEmail: string;
  // public ownerPhone: string;
  public partial: boolean;
  public dealerClaimNumber: string;
  public serialNumber: string;
  public retailSaleDate: Date;
  public failureDate: Date;
  public shipTo: string;
  public submittedBy: string;
  public webUser: string;
  public failureDescription: string;
  public freight: number;

  public orderItems: WarrantyItem[];

}
