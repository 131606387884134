import { _deserializable } from '../_deserializable';

export class WarrantyItem extends _deserializable {

  public description: string;
  public line: number;
  public part: string;
  public qtyOrdered: number;
  public unitPrice: number;
  public uom: string;

  public get linePrice(): number {
    return this.unitPrice * this.qtyOrdered;
  }

}
