import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import Litepicker from 'litepicker';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppAlerts, FormValidators } from 'src/app/constants';
import { IKeyValuePair } from 'src/app/domain/kvp';
import { Customer, Item, ShipTo, ShipVia } from 'src/app/domain/models';
import { IdentityService, OrderService, UtilityService } from 'src/app/services';
import { ButtonRendererComponent } from '../../cell-renderers';
import { AddShipToRequest, ItemAvailabilityRequest, ItemsRequest, MinimumRequiredDateRequest, ShipToRequest, SubmitOrderRequest } from 'src/app/domain/requestresponseobjects';
import { AddItemToWarrantyDialog, CreateShiptoDialog } from '../../dialogs';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { DateTime } from 'litepicker/dist/types/datetime';
import { WarrantyChannel } from 'src/app/domain/kvp/warranty-channel.kvp';
import { SubmitWarrantyClaimRequest } from 'src/app/domain/requestresponseobjects/submit-warranty-claim.request';
import { WarrantyItem } from 'src/app/domain/models/warranty-item.model';

@Component({
  selector: 'app-warranty-management',
  templateUrl: './warranty-management.component.html',
  styleUrls: ['./warranty-management.component.scss']
})
export class WarrantyManagementComponent implements AfterViewInit, OnDestroy, OnInit {

  private _customers: Customer[];
  private _datepickerRetailSale: Litepicker;
  private _datepickerFailure: Litepicker;
  private _gridApi: any;
  private _parts: Item[];
  private _subCustomerAutoTrigger: Subscription;
  private _subSelectedCustomer: Subscription;
  private _subSelectedShipTo: Subscription;

  public columnDefs = [];
  public filteredCustomers: Observable<Customer[]>;
  public form: FormGroup;
  public formSubmitted: boolean = false;
  public frameworkComponents: any = { buttonRenderer: ButtonRendererComponent };
  public isDealer: boolean = this.identityService.userIsInRole('Dealer');
  public warrantyChannels: IKeyValuePair<string>[] = WarrantyChannel.collection;
  // public orderDate: Date = new Date();
  public warrantyClaimItems: WarrantyItem[] = [];
  // public requiredDate: Date = new Date();
  public shipTos: ShipTo[];
  public submitAttempted: boolean = false;
  public retailSaleDate: Date = new Date();
  public failureDate: Date = new Date();



  @ViewChild('customerAutoInput', { read: MatAutocompleteTrigger }) customerAutoTrigger: MatAutocompleteTrigger;

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    private dialog: MatDialog,
    private identityService: IdentityService,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private utilityService: UtilityService) {
      this.form = this.formBuilder.group({
        // comments: [null, Validators.required],
        // ownerContact: null,
        // ownerEmail: [null, Validators.compose([Validators.email])],
        // ownerPhone: null,
        partialShipment: true,
        dealerClaimNumber: [null, Validators.required],
        selectedCustomer: [null, Validators.compose([Validators.required, this._autocompleteValidator.bind(this)])],
        selectedShipTo: [null, Validators.required],
        failureDescription: [null, Validators.required],
        serialNumber: [null, Validators.required],
        freight: [null, Validators.pattern(/^-?\d+(\.\d+)?$/)]
      });
  }

  ngAfterViewInit() {
    if (!this.isDealer)
      this._subCustomerAutoTrigger = this.customerAutoTrigger.panelClosingActions.subscribe(() => {
        if (this.customerAutoTrigger.activeOption)
          this.f.selectedCustomer.setValue(this.customerAutoTrigger.activeOption.value);
      });
  }
  ngOnDestroy() {
    if (this._subCustomerAutoTrigger)
      this._subCustomerAutoTrigger.unsubscribe();
    if (this._subSelectedCustomer)
      this._subSelectedCustomer.unsubscribe();
    if (this._subSelectedShipTo)
      this._subSelectedShipTo.unsubscribe();
  }

  ngOnInit() {
    this._setColumnDefs();

    this._subSelectedCustomer = this.f.selectedCustomer.valueChanges
      .subscribe((val) => {
        if (this.f.selectedCustomer.valid) {
          this._loadShipTos(null);
          this._loadItems();
        }
      });

      this._subSelectedShipTo = this.f.selectedShipTo.valueChanges
        .subscribe((val) => {
          if (val === 'ADD_NEW') {
            let ref = this.dialog.open(
              CreateShiptoDialog,
              {
                width: '100vw',
                data: {
                  parts: this._parts
                }
              });
            ref.afterClosed()
              .subscribe(result => {
                if (!result)
                  this.f.selectedShipTo
                    .setValue(this.shipTos.filter(st => { return st.shipToType === 'CUSTOMER'; })[0].ship);
                else
                  this.orderService.addShipTo$(new AddShipToRequest().deserialize({
                    attn: result.attn,
                    city: result.city,
                    country: result.country.country,
                    customer: this.f.selectedCustomer.value.customer,
                    line1: result.line1,
                    line2: result.line2,
                    line3: result.line3,
                    name: result.name,
                    phone: result.phone,
                    state: result.state.state,
                    zip: result.zip
                  }))
                    .subscribe(response => {
                      this._loadShipTos(response.ship);
                    });
              })
          }
        });

    // this._loadMinimumOrderRequiredDate();
    this._loadCustomers();
    this._setDatePickers();
  }

  private _autocompleteValidator(control: AbstractControl) {
    return control.value && typeof control.value === 'string'
      ? { autocomplete: true }
      : null;
  }

  private _defaultCustomer(): Customer {
    if (this._customers.length === 0)
      return null;

    var customerMatch = this._customers.filter(c => {
      return c.customer.startsWith('CS');
    });

    return (customerMatch && customerMatch.length && customerMatch.length > 0)
      ? customerMatch[0]
      : this._customers[0];
  }

  private _filterCustomers(match: any): Customer[] {
    // ensure string
    match += '';

    // ignore until length 3
    if (match.length < 3)
      return [];

    // initialize priority arrays
    let priority1Matches: Customer[] = [];
    let priority2Matches: Customer[] = [];
    let priority3Matches: Customer[] = [];
    let priority4Matches: Customer[] = [];

    // iterate
    for (var i = 0; i < this._customers.length; i++) {
      // priority 1: customer startsWith
      if (this._customers[i].customer.toLowerCase().startsWith(match.toLowerCase()))
        priority1Matches.push(this._customers[i]);
      // priority 2: customer contains
      else if (this._customers[i].customer.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority2Matches.push(this._customers[i]);
      // priority 3: name startsWith
      else if (this._customers[i].name.toLowerCase().startsWith(match.toLowerCase()))
        priority3Matches.push(this._customers[i]);
      // priority 4: name containas
      else if (this._customers[i].name.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority4Matches.push(this._customers[i]);
    }

    // concat, return
    return priority1Matches.concat(priority2Matches).concat(priority3Matches).concat(priority4Matches);
  }

  private _loadCustomers(): void {
    if (this.isDealer) {
      this._customers = this.identityService.getDealerCustomers();
      this.filteredCustomers = of(this._customers);
      this.f.selectedCustomer.setValue(this._defaultCustomer());
    }
    else {
      this.filteredCustomers = this.f.selectedCustomer.valueChanges
        .pipe(
          map(val => this._filterCustomers(val)));

      this.identityService.customers$()
        .subscribe(response => {
          this._customers = response.customers;
        });
    }
  }

  private _loadItems(): void {

    this.orderService.items$(new ItemsRequest().deserialize({ customer: this.f.selectedCustomer.value.customer, pricelist: this.identityService.getPricelist() }))
      .subscribe(response => {
        this._parts = response.items;
      })
  }
  private _loadShipTos(shipToSelect): void {
    this.orderService.shipTos$(new ShipToRequest().deserialize({ customer: this.f.selectedCustomer.value.customer }))
      .subscribe(response => {
        this.shipTos = response.shipTos;
        this.f.selectedShipTo
          .setValue(
            shipToSelect
              ? shipToSelect
              : this.shipTos.filter(st => { return st.shipToType === 'CUSTOMER'; })[0].ship);
      });
  }

  private _setColumnDefs(): void {
    this.columnDefs = [{
      // part
      field: 'part',
      headerName: 'Part',
      filter: false,
      flex: 2
    }, {
      // description
      field: 'description',
      headerName: 'Description',
      filter: false,
      flex: 4
    }, {
      // uom
      field: 'uom',
      headerName: 'UoM',
      filter: false,
      flex: 2
    }, {
      // unit price
      field: 'unitPrice',
      headerName: 'Unit Price',
      filter: false,
      flex: 2,
      valueFormatter: this.utilityService.gridFormatterCurrency
    }, {
      // qtyOrdered
      editable: true,
      field: 'qtyOrdered',
      headerName: 'Qty Ordered',
      filter: false,
      flex: 2
    }, {
      // line price
      field: 'linePrice',
      headerName: 'Line Price',
      filter: false,
      flex: 2,
      valueFormatter: this.utilityService.gridFormatterCurrency
    }, {
      // delete btn
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.rowDelete.bind(this),
        class: 'grid-button-r',
        icon: 'clear'
      },
      headerName: '',
      filter: false,
      flex: 1
    }];
  }

  private _setDatePickers(): void {
    this._datepickerRetailSale = new Litepicker({
      element: document.getElementById('retailSaleDate'),
      format: 'MM/DD/YYYY',
      setup: (picker) => {
        picker.on('selected', (d: DateTime) => {
          this.retailSaleDate = this.f.retailSaleDate = d.toJSDate();
        })
      },
      singleMode: true,
      startDate: this.retailSaleDate
    });
    this._datepickerFailure = new Litepicker({
      element: document.getElementById('failureDate'),
      format: 'MM/DD/YYYY',
      maxDate: this.failureDate,
      setup: (picker) => {
        picker.on('selected', (d: DateTime) => {
          this.failureDate = this.f.failureDate = d.toJSDate();

        })
      },
      singleMode: true,
      startDate: this.failureDate
    });
  }

  private _validateForm(): void {
    Object.keys(this.f).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl)
        control.markAsTouched({ onlySelf: true });
    });
  }

  public addItemEnabled(): boolean {
    return (this._parts && this._parts.length && this._parts.length > 0);
  }

  public displayCustomerWith(customer: Customer): string | undefined {
    return customer
      ? `${customer.customer} (${customer.name})`
      : undefined;
  }

  public getSelectedCustomerError(): string {
    return this.f.selectedCustomer.hasError('required')
      ? FormValidators.required
      : this.f.selectedCustomer.hasError('autocomplete')
        ? FormValidators.autocomplete
        : FormValidators.none;
  }
  public getWarrantyClaimItemsError(): string {
    return this.warrantyClaimItemsHasError()
      ? FormValidators.warrantyClaimItems
      : FormValidators.none;
  }
  public getOwnerEmailError(): string {
    return this.f.ownerEmail.hasError('email')
      ? FormValidators.email
      : FormValidators.none;
  }
  public getDealerClaimNumber(): string {
    return this.f.dealerClaimNumber.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getSelectedShipToError(): string {
    return this.f.selectedShipTo.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getFailureDescriptionError(): string {
    return this.f.failureDescription.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getSerialNumberError(): string {
    return this.f.serialNumber.hasError('required')
    ? FormValidators.required
    : FormValidators.none;
  }
  public getRetailSaleDateError(): string {
    return this.f.retailSaleDate.hasError('required')
    ? FormValidators.required
    : FormValidators.none;
  }
  public getFailureDateError(): string {
    return this.f.failureDate.hasError('required')
    ? FormValidators.required
    : FormValidators.none;
  }
  public getFreightError(): string {
    return this.f.freight.hasError('required')
    ? FormValidators.required
    : FormValidators.none;
  }

  public onCellValueChanged(ev: any) {
    if (ev.data.qtyOrdered > ev.data.qtyAvailable) {
      this.orderService.itemAvailability$(new ItemAvailabilityRequest().deserialize({ part: ev.data.part, qtyOrdered: Number(ev.data.qtyOrdered) }))
        .subscribe(response => {
          if (new Date(response.availableDate) > new Date(ev.data.dueDate)) {
            ev.data.dueDate = new Date(response.availableDate);
            this._gridApi.refreshCells();
          }
        });
    }
      else {
        // ev.data.dueDate = new Date(this.requiredDate);
        this._gridApi.refreshCells();
      }
  }

  public onGridReady(ev: any): void {
    this._gridApi = ev.api;
  }

  public openAddItemModal(): void {
    let ref = this.dialog.open(
      AddItemToWarrantyDialog,
      {
        width: '100vw',
        data: {
          parts: this._parts
        }
      });

    ref.afterClosed()
      .subscribe(result => {
        if (!result)
          return;

      let warrantyItem;

      if(result.selectedPart.hasOwnProperty('part')){
        warrantyItem = new WarrantyItem().deserialize({
          description: result.selectedPart.part === "ITEM USED" ? result.description : result.selectedPart.description,
          part: result.selectedPart.part,
          qtyAvailable: result.selectedPart.qtyAvailable,
          qtyOrdered: Number(result.qtyToOrder),
          unitPrice: Number(result.unitPrice),
          uom: result.selectedPart.uom
        });
      } else {
        let part = JSON.stringify(result.selectedPart);
        part = part.substring(1, part.length - 1);
        warrantyItem = new WarrantyItem().deserialize({
          ...result,
          qtyOrdered: Number(result.qtyToOrder),
          unitPrice: Number(result.unitPrice),
          part: part,
          description: part
        });
      }



        this.warrantyClaimItems.unshift(warrantyItem);
        this._gridApi.applyTransaction({
          add: [warrantyItem],
          addIndex: 0,
        });

      });
  }

  public warrantyClaimItemsHasError(): boolean {
    return this.warrantyClaimItems.length < 1;
  }

  public rowDelete(params: any): void {
    this.warrantyClaimItems = this.warrantyClaimItems.filter(i => i.part !== params.data.part);
  }

  public async submit(): Promise<void> {
    this.submitAttempted = true;

    this._validateForm();

    if (this.formSubmitted || !this.form.valid)
      return;

    this.formSubmitted = true;

    // set item indexing
    let idx = 1;
    this.warrantyClaimItems.forEach(i => {
      i.line = idx;
      idx++;
    });

    // build request
    const request = new SubmitWarrantyClaimRequest().deserialize({
      channel: "WAR",
      comments: null,
      customer: this.f.selectedCustomer.value.customer,
      retailSaleDate: new Date(this.retailSaleDate),
      failureDate: new Date(this.failureDate),
      serialNumber: this.f.serialNumber.value,
      // ownerContact: this.f.ownerContact.value,
      // ownerEmail: this.f.ownerEmail.value,
      // ownerPhone: this.f.ownerPhone.value,
      partial: this.f.partialShipment.value,
      dealerClaimNumber: this.f.dealerClaimNumber.value,
      shipTo: this.f.selectedShipTo.value,
      submittedBy: `${this.identityService.userFullName}`,
      webUser: this.isDealer
        ? 'CWC'
        : 'CWCI',

      orderItems: this.warrantyClaimItems,
      failureDescription: this.f.failureDescription.value,
      freight: new Number(this.f.freight.value)
    });

    // submit
    this.orderService.submitWarrantyClaim$(request)
      .subscribe(response => {
        if (response.status == 'SUCCESS')
          Swal.fire('Success!', AppAlerts.WarrantyClaimEntry.success(response.warranty), 'success')
            .then(() => {
              this.submitAttempted = false;
              this.formSubmitted = false;
              window.location.reload();
            });
        else {
          Swal.fire('Something went wrong', AppAlerts.WarrantyClaimEntry.failure, 'error')
            .then(() => {
              this.submitAttempted = false;
              this.formSubmitted = false;
            })
        }
      });
  }
}
